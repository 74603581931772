import {Component, OnInit, ViewChild} from '@angular/core';
import {PageHeaderInfoService} from '../../../service/page-header-info-service';
import {CenterApi} from '../../../data/api/center-api';
import {DialogService} from '../../../service/dialog-service';
import {ModalService} from '../../../service/modal-service';
import {Center} from '../../../data/response/centers/centers-response';
import {NgForm} from '@angular/forms';
import {CountryApi} from '../../../data/api/country-api';
import {CityApi} from '../../../data/api/city-api';
import {Country} from '../../../data/response/countries/countries-response';
import {City} from '../../../data/response/cities/cities-response';
import {EditImageComponent} from '../../../component/edit-image/edit-image.component';
import {environment} from '../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {CentersCounters} from '../../../data/response/centers/centers-counters-response';

declare var $;

@Component({
  selector: 'app-centers',
  templateUrl: './centers.component.html',
  styleUrls: ['./centers.component.css']
})
export class CentersComponent implements OnInit {

  @ViewChild('editImageComponent')
  editImageComponent: EditImageComponent;
  centers: Center[] = [];
  countries: Country [] = [];
  cities: City[] = [];

  center: {
    name: {
      ar: string,
      en: string
    }, contacts: {
      phones: string[],
      emails: string[]
    }, index: number, id: string, submitForm: boolean
  };

  regionAr: string;
  regionEn: string;
  streetAr: string;
  streetEn: string;
  buildingAr: string;
  buildingEn: string;
  locationLat: number;
  locationLng: number;
  countryId: string;
  cityId: string;

  showCounters = false;
  centersCounters: CentersCounters;

  constructor(private headerService: PageHeaderInfoService, private centerApi: CenterApi,
              private dialogService: DialogService, private modalService: ModalService,
              private countryApi: CountryApi, private cityApi: CityApi,
              private translator: TranslateService) {
    this.headerService.setData('fa-hospital', 'app.hospitals', '');
  }

  ngOnInit() {
    this.setDefaultCenter();
    this.loadCountries();
    this.loadCenters();
    this.translator.get('app.center_phones').subscribe(val => {
      const inputs = $('rl-tag-input form input');
      inputs.eq(0).attr('placeholder', val);
      inputs.eq(1).attr('placeholder', this.translator.instant('app.center_mails'));
    });
    this.loadCentersCounters();
  }

  addCenterClick() {
    this.setDefaultCenter();
    this.modalService.open('centerModal');
  }

  showCenterPhones(cen: Center) {
    const phones = cen.contacts.phones;
    let content = '<div class="row justify-content-center">';
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < phones.length; i++) {
      content += `<button class="btn btn-oblong btn-primary m-1 text-white">${phones[i]}</button>`;
    }
    content += '</div>';
    this.dialogService.showText(content);
  }

  showCenterMails(cen: Center) {
    const emails = cen.contacts.emails;
    let content = '<div class="row justify-content-center">';
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < emails.length; i++) {
      content += `<button class="btn btn-oblong btn-primary m-1 text-white">${emails[i]}</button>`;
    }
    content += '</div>';
    this.dialogService.showText(content);
  }

  changeCenterActivationClick(i: number, cen: Center) {
    // check if center is active or not.
    if (cen.isActive) {
      // deactivate.
      this.centerApi.deactivate(cen._id).subscribe(res => {
        cen.isActive = false;
        this.dialogService.successMessage(res.message);
      });
    } else {
      // activate.
      this.centerApi.activate(cen._id).subscribe(res => {
        cen.isActive = true;
        this.dialogService.successMessage(res.message);
      });
    }
  }

  editCenterClick(i: number, cen: Center) {
    this.countryId = cen.address.country._id;
    this.cityId = cen.address.city._id;
    this.regionAr = cen.address.regoin.ar;
    this.regionEn = cen.address.regoin.en;
    this.streetAr = cen.address.street.ar;
    this.streetEn = cen.address.street.en;
    this.locationLat = cen.address.mapLocation.latitude;
    this.locationLng = cen.address.mapLocation.longitude;
    this.buildingEn = cen.address.building.en;
    this.buildingAr = cen.address.building.ar;
    const center = {
      id: cen._id,
      index: i,
      name: cen.name,
      contacts: {
        phones: cen.contacts.phones,
        emails: cen.contacts.emails
      },
      submitForm: false
    };

    this.center = center;
    this.modalService.open('centerModal');
  }

  saveCenter(centerForm: NgForm) {
    this.center.submitForm = true;
    if (!centerForm.valid || this.center.contacts.phones.length === 0
      || this.center.contacts.emails.length === 0 || this.countryId === '' || this.cityId === '') {
      return;
    }
    // check if add or edit form
    const body: any = {};
    body.name = {};
    body.name.ar = this.center.name.ar;
    body.name.en = this.center.name.en;
    body.address = {};
    body.address.countryId = this.countryId;
    body.address.cityId = this.cityId;
    body.address.regoin = {};
    body.address.regoin.ar = this.regionAr;
    body.address.regoin.en = this.regionEn;
    body.address.street = {};
    body.address.street.ar = this.streetAr;
    body.address.street.en = this.streetEn;
    body.address.mapLocation = {};
    body.address.mapLocation.latitude = this.locationLat;
    body.address.mapLocation.longitude = this.locationLng;
    body.address.building = {};
    body.address.building.ar = this.buildingAr;
    body.address.building.en = this.buildingEn;
    body.contacts = {};
    body.contacts.emails = this.center.contacts.emails;
    body.contacts.phones = this.center.contacts.phones;
    if (this.center.index > -1) {
      // edit
      this.centerApi.update(this.center.id, body).subscribe(res => {
        this.centers[this.center.index] = res.data[0];
        this.modalService.close('centerModal');
        this.dialogService.successMessage(res.message);
        this.setDefaultCenter();
      });
    } else {
      // add
      this.centerApi.save(body).subscribe(res => {
        this.centers.push(res.data[0]);
        this.modalService.close('centerModal');
        this.dialogService.successMessage(res.message);
        this.setDefaultCenter();
      });
    }
  }

  private loadCenters() {
    this.centerApi.centers(1, 1000000).subscribe(res => {
      this.centers = res.data;
    });
  }

  private setDefaultCenter() {
    this.center = {
      contacts: {
        emails: [], phones: []
      },
      id: '', index: -1, name: {ar: '', en: ''}, submitForm: false
    };
    this.countryId = '';
    this.cityId = '';
    this.buildingAr = '';
    this.buildingEn = '';
    this.locationLat = 0.0;
    this.locationLng = 0.0;
    this.regionAr = '';
    this.regionEn = '';
    this.streetAr = '';
    this.streetEn = '';
  }

  getLocation(cen: Center) {
    return `http://maps.apple.com/maps?q=${cen.address.mapLocation.latitude},${cen.address.mapLocation.latitude}`;
  }

  private loadCountries() {
    this.countryApi.activeCountries(1, 1000).subscribe(res => {
      this.countries = res.data;
    });
  }

  loadCountryCities(countryId: string) {
    this.countryId = countryId;
    this.cities = [];
    if (countryId !== '') {
      this.cityApi.activeCities(countryId, 1, 10000).subscribe(res => {
        this.cities = res.data;
      });
    }
  }

  editImageClick(i: number, cen: Center) {
    this.editImageComponent.setImage(cen.avatar, cen._id, 'center', (newLink) => {
      cen.avatar = newLink;
    });
    this.editImageComponent.open();
  }

  getCenterImage(avatar: string) {
    return environment.baseLink.concat(avatar);
  }

  private loadCentersCounters() {
    this.centerApi.statistics().subscribe(res => {
      this.centersCounters = res.data[0];
    });
  }
}

