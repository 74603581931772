import {Component, OnInit, ViewChild} from '@angular/core';
import {SpecializationApi} from '../../../data/api/specialization-api';
import {ModalService} from '../../../service/modal-service';
import {Specialization} from '../../../data/response/specializations/specializations-response';
import {environment} from '../../../environments/environment';
import {NgForm} from '@angular/forms';
import {DialogService} from '../../../service/dialog-service';
import {EditImageComponent} from '../../../component/edit-image/edit-image.component';
import {PageHeaderInfoService} from '../../../service/page-header-info-service';
import {
  SpecializationsCounters,
  SpecializationsCountersResponse
} from '../../../data/response/specializations/specializations-counters-response';

@Component({
  selector: 'app-specializations',
  templateUrl: './specializations.component.html',
  styleUrls: ['./specializations.component.css']
})
export class SpecializationsComponent implements OnInit {

  @ViewChild('editImageComponent')
  editImageComponent: EditImageComponent;

  specialization: { nameAr: string, nameEn: string, id: string, index: number, submitForm: boolean };
  specializations: Specialization[] = [];
  showCounters = false;
  specializationsCounters: SpecializationsCounters;

  constructor(private specializationApi: SpecializationApi,
              private modalService: ModalService,
              private dialogService: DialogService, private headerInfoService: PageHeaderInfoService) {
    headerInfoService.setData('fa-thermometer-half', 'app.specializations', '');
  }

  ngOnInit() {
    this.setDefaultSpecialization();
    this.loadSpecializations();
    this.loadSpecializationsCounters();
  }

  private setDefaultSpecialization() {
    this.specialization = {id: '', index: -1, nameAr: '', nameEn: '', submitForm: false};
  }

  private loadSpecializations() {
    this.specializationApi.specializations(1, 10000).subscribe(res => {
      this.specializations = res.data;
    });
  }

  getSpecializationImage(avatar: string) {
    return environment.baseLink.concat(avatar);
  }

  changeSpecializationActiveClick(i: number, spec: Specialization) {
    // check if specialization is activated or not.
    if (spec.isActive) {
      // deactivate
      this.specializationApi.deActivate(spec._id).subscribe(res => {
        spec.isActive = false;
        this.dialogService.successMessage(res.message);
      });
    } else {
      // activate.
      this.specializationApi.activate(spec._id).subscribe(res => {
        spec.isActive = true;
        this.dialogService.successMessage(res.message);
      });
    }
  }

  editSpecializationClick(i: number, spec: Specialization) {
    this.specialization = {index: i, id: spec._id, nameEn: spec.name.en, nameAr: spec.name.ar, submitForm: false};
    this.modalService.open('specMdoal');
  }

  saveSpecializationClick(form: NgForm) {
    this.specialization.submitForm = true;
    if (!form.valid) {
      return;
    }
    // check if add or edit specialization.
    const body: any = {};
    body.name = {};
    body.name.ar = this.specialization.nameAr;
    body.name.en = this.specialization.nameEn;
    if (this.specialization.index > -1) {
      // edit.
      this.specializationApi.update(this.specialization.id, body)
        .subscribe(res => {
          this.modalService.close('specMdoal');
          const spec = this.specializations[this.specialization.index];
          spec.name = res.data[0].name;
          this.dialogService.successMessage(res.message);
          this.setDefaultSpecialization();
        });
    } else {
      // add
      this.specializationApi.save(body)
        .subscribe(res => {
          this.modalService.close('specMdoal');
          this.specializations.unshift(res.data[0]);
          this.dialogService.successMessage(res.message);
          this.setDefaultSpecialization();
        });
    }
  }

  addSpecializationClick() {
    this.setDefaultSpecialization();
    this.modalService.open('specMdoal');
  }

  editImageClick(i: number, spec: Specialization) {
    this.editImageComponent.setImage(spec.avatar, spec._id, 'speciality', (newLink) => {
      spec.avatar = newLink;
    });
    this.editImageComponent.open();
  }

  deleteSpecializationClick(i: number, spec: Specialization) {
    this.dialogService.showDeleteItem(() => {
      this.specializationApi.delete(spec._id).subscribe(res => {
        this.specializations.splice(i, 1);
        this.dialogService.successMessage(res.message);
      });
    });
  }

  private loadSpecializationsCounters() {
    this.specializationApi.statistics().subscribe(res => {
      this.specializationsCounters = res.data[0];
    });
  }
}
