import {Pipe, PipeTransform} from '@angular/core';
import {LanguageService} from '../service/language-service';

@Pipe({
  name: 'lang',
  pure: false
})
export class LangPipe implements PipeTransform {

  appLanguage: string = null;

  constructor(private translator: LanguageService) {
    this.appLanguage = translator.getAppLanguage();
  }

  transform(value: any, ...args: any[]): any {
    if (value == null) {
      return '';
    }

    if (this.appLanguage === 'ar') {
      return value.ar;
    }
    return value.en;
  }

}
