import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {BookingsCountersResponse} from '../response/bookings/bookings-counters-response';
import {BookingsResponse} from '../response/bookings/bookings-response';

@Injectable()
export class BookingApi {

  constructor(private http: HttpClient) {

  }

  public bookings(searchBy): Observable<BookingsResponse> {
    return this.http.get<BookingsResponse>(environment.apiLink.concat(`bookings${searchBy}`));
  }

  public confirm(id: string): Observable<BookingsResponse> {
    return this.http.put<BookingsResponse>(environment.apiLink.concat(`bookings/${id}/confirm`), {});
  }

  public cancel(id: string): Observable<BookingsResponse> {
    return this.http.put<BookingsResponse>(environment.apiLink.concat(`bookings/${id}/cancel`), {});
  }


  public statistics(): Observable<BookingsCountersResponse> {
    return this.http.get<BookingsCountersResponse>(environment.apiLink.concat(`bookings/statistics`));
  }


}
