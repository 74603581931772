import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {CentersResponse} from '../response/centers/centers-response';
import {Observable} from 'rxjs';
import {CentersCountersResponse} from '../response/centers/centers-counters-response';

@Injectable()
export class CenterApi {

  constructor(private http: HttpClient) {

  }

  public centers(page: number, size: number): Observable<CentersResponse> {
    return this.http.get<CentersResponse>(environment.apiLink.concat(`centers?page=${page}&size=${size}&lang=all&status=ALL`));
  }


  public activeCenters(page: number, size: number): Observable<CentersResponse> {
    return this.http.get<CentersResponse>(environment.apiLink.concat(`centers?page=${page}&size=${size}&lang=all`));
  }

  public save(data): Observable<CentersResponse> {
    return this.http.post<CentersResponse>(environment.apiLink.concat('centers?lang=all'), data);
  }

  public update(id: string, data) {
    return this.http.put<CentersResponse>(environment.apiLink.concat(`centers/${id}/?lang=all`), data);
  }

  public activate(id: string) {
    return this.http.put<CentersResponse>(environment.apiLink.concat(`centers/${id}/activate?lang=all`), {});
  }

  public deactivate(id: string) {
    return this.http.put<CentersResponse>(environment.apiLink.concat(`centers/${id}/deactivate?lang=all`), {});
  }

  public statistics(): Observable<CentersCountersResponse> {
    return this.http.get<CentersCountersResponse>(environment.apiLink.concat(`centers/statistics`));
  }

}
