import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CommonService} from './common-service';
import {AdminService} from './admin-service';
import {tap} from 'rxjs/internal/operators/tap';
import {DialogService} from './dialog-service';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {LanguageService} from './language-service';
import {ToastService} from './toast-service';

@Injectable(
  {
    providedIn: 'root'
  }
)
export class NetworkInterceptor implements HttpInterceptor {

  constructor(private commonService: CommonService,
              private adminService: AdminService,
              private dialogService: DialogService,
              private langService: LanguageService,
              private toastService: ToastService,
              private router: Router) {

  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // modify request
    this.commonService.showLoader();
    const admin = this.adminService.getAdmin();

    if (admin) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${admin.token}`
        }
      });
    }

    const appLang = this.langService.getAppLanguage();
    request = request.clone({
      setHeaders: {
        'Accept-Language': appLang
      }
    });

    return next.handle(request)
      .pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
            this.commonService.hideLoader();
          }
        }, error => {
          this.commonService.hideLoader();
          if (error.status === 401) {
            // unauthorized.
            this.toastService.showErrorToast(error.error.message);
            this.adminService.removeAdmin();
            this.router.navigateByUrl('guest/login');
          } else {
            this.dialogService.showErrorDialog(error.error.message);
          }
        })
      );

  }

}
