import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {LoginApi} from '../../../data/api/login-api';
import {AdminService} from '../../../service/admin-service';
import {Router} from '@angular/router';

declare var $;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  admin: { email: string, password: string, submit: boolean };

  constructor(private loginApi: LoginApi, private adminService: AdminService, private router: Router) {
    this.setDefaultData();
  }

  ngOnInit() {
    const modal = $('div.modal-backdrop.fade.show');
    if (modal) {
      modal.hide();
    }
  }

  loginClick(form: NgForm) {
    this.admin.submit = true;
    if (!form.valid) {
      return;
    }
    // call api.
    this.loginApi.login(this.admin.email, this.admin.password)
      .subscribe(res => {
        const admin = res.data[0];
        admin.token = res.token;
        admin.refreshToken = res.refreshToken;
        this.adminService.setAdmin(admin, true);
        this.router.navigateByUrl(''); // navigate to home.
      });
  }

  private setDefaultData() {
    this.admin = {email: '', password: '', submit: false};
  }
}
