import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ServiceModule} from '../service/service-module';
import {ApiModule} from '../data/api/api-module';
import {GuardModule} from '../guard/guard-module';
import {LoginComponent} from '../ui/guest/login/login.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {APP_BASE_HREF} from '@angular/common';
import {BaseAdminComponent} from '../ui/admin/base-admin/base-admin.component';
import {AdminsComponent} from '../ui/admin/admins/admins.component';
import {DataTableModule} from 'angular-6-datatable';
import {ToastrModule} from 'ng6-toastr-notifications';
import {SpecializationsComponent} from '../ui/admin/specializations/specializations.component';
import {EditImageComponent} from '../component/edit-image/edit-image.component';
import {CountriesComponent} from '../ui/admin/countries/countries.component';
import {CitiesComponent} from '../ui/admin/cities/cities.component';
import {NationalitiesComponent} from '../ui/admin/nationalities/nationalities.component';
import {ProfileComponent} from '../ui/admin/profile/profile.component';
import {CentersComponent} from '../ui/admin/centers/centers.component';
import {RlTagInputModule} from 'angular2-tag-input/dist';
import {DoctorsComponent} from '../ui/admin/doctors/doctors.component';
import {BookingsComponent} from '../ui/admin/bookings/bookings.component';
import {ImgFallbackModule} from 'ngx-img-fallback';
import {NumberOnlyDirective} from '../directive/number_only_directive';
import {ArabicOnlyDirective} from '../directive/arabic_only_directive';
import {EnglishOnlyDirective} from '../directive/english_only_directive';
import {ChangePasswordComponent} from '../component/change-password-component/change-password.component';
import {LangPipe} from '../pipe/LangPipe';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DoctorTimesComponent} from '../ui/admin/doctors/doctor-times/doctor-times.component';
import {UsersComponent} from '../ui/admin/users/users.component';
import {BookingStatusPipe} from '../pipe/BookingStatusPipe';
import {BookingTypePipe} from '../pipe/BookingTypePipe';
import {DashboardComponent} from '../ui/admin/dashboard/dashboard.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


export const components = [
  AppComponent, LoginComponent, BaseAdminComponent, AdminsComponent,
  SpecializationsComponent, EditImageComponent, CountriesComponent,
  CitiesComponent, NationalitiesComponent, ProfileComponent,
  CentersComponent, DoctorsComponent, BookingsComponent,
  ChangePasswordComponent, DoctorTimesComponent,
  UsersComponent, DashboardComponent
];

export const directives = [
  NumberOnlyDirective,
  ArabicOnlyDirective,
  EnglishOnlyDirective
];

export const pipes = [
  LangPipe,
  BookingStatusPipe,
  BookingTypePipe
];

@NgModule({
  declarations: [
    ...components,
    ...directives,
    ...pipes
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    ToastrModule.forRoot(),
    AppRoutingModule,
    ServiceModule,
    FormsModule,
    HttpClientModule,
    ApiModule,
    GuardModule,
    DataTableModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    RlTagInputModule,
    ImgFallbackModule
  ],
  providers: [
    {provide: APP_BASE_HREF, useValue: '/clinic/'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
