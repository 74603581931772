import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

declare var $;

@Injectable()
export class LanguageService {

  constructor(private translator: TranslateService) {

  }

  public setAppLanguage() {
    let appLanguage = this.getAppLanguage();
    if (appLanguage === null) {
      appLanguage = 'ar';
      localStorage.setItem('language', 'ar');
    }
    this.translator.setDefaultLang(appLanguage);
    this.translator.use(appLanguage);
    const html = $('html');
    html.prop('lang', appLanguage);
    if (appLanguage === 'ar') {
      html.prop('dir', 'rtl');
      html.removeClass('ltr');
      html.addClass('rtl');
    } else {
      html.prop('dir', 'ltr');
      html.removeClass('rtl');
      html.addClass('ltr');
    }
  }

  public changeLanguage() {
    const currentLanguage = this.getAppLanguage();
    let newLanguage = '';
    if (currentLanguage === null) {
      newLanguage = 'en';
    } else if (currentLanguage === 'ar') {
      newLanguage = 'en';
    } else {
      newLanguage = 'ar';
    }
    localStorage.setItem('language', newLanguage);
    this.setAppLanguage();
  }


  public getAppLanguage() {
    return localStorage.getItem('language');
  }

}
