import {NgModule} from '@angular/core';
import {AdminService} from './admin-service';
import {CommonService} from './common-service';
import {NetworkInterceptor} from './network-interceptor';
import {DialogService} from './dialog-service';
import {ModalService} from './modal-service';
import {PageHeaderInfoService} from './page-header-info-service';
import {LanguageService} from './language-service';
import {ToastService} from './toast-service';

@NgModule(
  {
    providers: [
      AdminService,
      CommonService,
      NetworkInterceptor,
      DialogService,
      ModalService,
      PageHeaderInfoService,
      LanguageService,
      ToastService
    ]
  }
)
export class ServiceModule {

}
