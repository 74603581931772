import {Component, OnInit, ViewChild} from '@angular/core';
import {AdminApi} from '../../../data/api/admin-api';
import {DialogService} from '../../../service/dialog-service';
import {PageHeaderInfoService} from '../../../service/page-header-info-service';
import {Admin} from '../../../data/response/login/admin-response';
import {AdminService} from '../../../service/admin-service';
import {NgForm} from '@angular/forms';
import {environment} from '../../../environments/environment';
import {EditImageComponent} from '../../../component/edit-image/edit-image.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  @ViewChild('editImageComponent')
  editImageComponent: EditImageComponent;
  placeholderImage = 'assets/images/ic_user_placeholder.png';
  admin: Admin;
  password: { oldPassword: string, newPassword: string, retypedPassword: string, adminId: string, submitForm: boolean };

  constructor(private adminApi: AdminApi, private dialogService: DialogService,
              private headerInfo: PageHeaderInfoService, private adminService: AdminService) {
    headerInfo.setData('fa-user', 'app.edit_profile', '');
  }

  ngOnInit() {
    this.admin = this.adminService.getAdmin();
    this.setDefaultPassword();
  }

  changePassword(passwordForm: NgForm) {
    this.password.submitForm = true;
    if (!passwordForm.valid || this.password.newPassword !== this.password.retypedPassword) {
      return;
    }
    const body: any = {};
    body.oldPassword = this.password.oldPassword;
    body.newPassword = this.password.newPassword;
    body.confirmNewPassword = this.password.retypedPassword;
    this.adminApi.changePassword(body)
      .subscribe(res => {
        this.dialogService.successMessage(res.message);
      });
  }

  private setDefaultPassword() {
    this.password = {adminId: this.admin._id, newPassword: '', oldPassword: '', retypedPassword: '', submitForm: false};
  }

  getAdminImage() {
    return environment.baseLink.concat(this.admin.avatar);
  }

  changeProfileImageClick() {
    this.editImageComponent.setImage(this.admin.avatar, this.admin._id, 'admin', link => {
      this.admin.avatar = link;
    });
    this.editImageComponent.open();
  }
}
