import {Component, OnInit} from '@angular/core';
import {Availablity} from '../../../../data/response/doctors/doctors-response';
import {ModalService} from '../../../../service/modal-service';

@Component({
  selector: 'app-doctor-times',
  templateUrl: './doctor-times.component.html',
  styleUrls: ['./doctor-times.component.css']
})
export class DoctorTimesComponent implements OnInit {
  times: Availablity[] = [];

  constructor(private modalService: ModalService) {
  }

  ngOnInit() {
  }

  public setTimes(times: Availablity[]) {
    this.times = times;
  }

  public open() {
    this.modalService.open('timesModal');
  }

  public close() {
    this.modalService.close('timesModal');
  }

}
