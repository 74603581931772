import {NgModule} from '@angular/core';
import {ServiceModule} from '../../service/service-module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NetworkInterceptor} from '../../service/network-interceptor';
import {LoginApi} from './login-api';
import {AdminApi} from './admin-api';
import {CenterApi} from './center-api';
import {SpecializationApi} from './specialization-api';
import {UploadImageApi} from './upload-image-api';
import {CountryApi} from './country-api';
import {CityApi} from './city-api';
import {NationalityApi} from './nationality-api';
import {DoctorApi} from './doctor-api';
import {BookingApi} from './booking-api';
import {UserApi} from './user-api';
import {DashboardApi} from './dashboard-api';

@NgModule({
  imports: [
    ServiceModule,
    HttpClientModule
  ],
  providers: [

    {
      provide: HTTP_INTERCEPTORS,
      useClass: NetworkInterceptor,
      multi: true
    },
    LoginApi,
    AdminApi,
    CenterApi,
    SpecializationApi,
    UploadImageApi,
    CountryApi,
    CityApi,
    NationalityApi,
    DoctorApi,
    BookingApi,
    UserApi,
    DashboardApi
  ]
})
export class ApiModule {

}
