import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {CountriesResponse} from '../response/countries/countries-response';
import {Observable} from 'rxjs';
import {CountriesCountersResponse} from '../response/countries/countries-counters-response';

@Injectable()
export class CountryApi {

  constructor(private http: HttpClient) {

  }

  public countries(page: number, size: number): Observable<CountriesResponse> {
    return this.http.get<CountriesResponse>(environment.apiLink.concat(`countries?page=${page}&size=${size}&lang=all&status=ALL`));
  }

  public activeCountries(page: number, size: number): Observable<CountriesResponse> {
    return this.http.get<CountriesResponse>(environment.apiLink.concat(`countries?page=${page}&size=${size}&lang=all`));
  }

  public save(data): Observable<CountriesResponse> {
    return this.http.post<CountriesResponse>(environment.apiLink.concat(`countries?lang=all`), data);
  }

  public update(id: string, data): Observable<CountriesResponse> {
    return this.http.put<CountriesResponse>(environment.apiLink.concat(`countries/${id}/?lang=all`), data);
  }

  public activate(id: string): Observable<CountriesResponse> {
    return this.http.put<CountriesResponse>(environment.apiLink.concat(`countries/${id}/activate?lang=all`), {});
  }

  public deActivate(id: string): Observable<CountriesResponse> {
    return this.http.put<CountriesResponse>(environment.apiLink.concat(`countries/${id}/deactivate?lang=all`), {});
  }

  public statistics(): Observable<CountriesCountersResponse> {
    return this.http.get<CountriesCountersResponse>(environment.apiLink.concat(`countries/statistics`), {});
  }
}
