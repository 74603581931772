// CommonJS
import Swal from 'sweetalert2';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable(
  {
    providedIn: 'root'
  }
)
export class DialogService {

  constructor(private translator: TranslateService) {

  }


  public showText(text: string) {
    Swal.fire(text);
  }

  public showErrorDialog(error: string) {
    this.translator.get('errors.error').subscribe(value => {
      Swal.fire({
        title: value,
        text: error,
        type: 'error',
        confirmButtonText: this.translator.instant('app.ok')
      });

    });
  }


  public showError(title: string = 'Error', message: string = 'Error in server.') {
    Swal.fire(
      title,
      message,
      'error'
    );
  }


  public showErrorMessage(message: string = 'Error in server.') {
    Swal.fire(
      this.translator.instant('errors.error'),
      this.translator.instant(message),
      'error'
    );
  }

  public showDeleteItem(callback: () => any) {
    Swal.fire({
      title: this.translator.instant('dialog.delete_title'),
      text: this.translator.instant('dialog.delete_message'),
      type: 'error',
      showCancelButton: true,
      confirmButtonColor: '#00afb7',
      cancelButtonColor: '#b73100',
      confirmButtonText: this.translator.instant('dialog.yes'),
      cancelButtonText: this.translator.instant('dialog.no'),
    }).then((result) => {
      if (result.value) {
        callback();

      }
    });
  }


  public confirmation(titleKey: string, callback: () => any) {
    Swal.fire({
      title: this.translator.instant(titleKey),
      type: 'error',
      showCancelButton: true,
      confirmButtonColor: '#00afb7',
      cancelButtonColor: '#b73100',
      confirmButtonText: this.translator.instant('Yes'),
      cancelButtonText: this.translator.instant('No'),
    }).then((result) => {
      if (result.value) {
        callback();

      }
    });
  }

  public confirmationWithTitle(titleKey: string, callback: () => any) {
    Swal.fire({
      title: titleKey,
      type: 'info',
      showCancelButton: true,
      confirmButtonColor: '#00afb7',
      cancelButtonColor: '#b73100',
      confirmButtonText: this.translator.instant('app.yes'),
      cancelButtonText: this.translator.instant('app.no'),
    }).then((result) => {
      if (result.value) {
        callback();
      }
    });
  }


  public successMessage(message: string = 'Done') {
    Swal.fire({
      type: 'success',
      title: this.translator.instant(message),
      showConfirmButton: false,
      timer: 1500
    });

  }
}
