import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {UsersResponse} from '../response/users/users-response';
import {Observable} from 'rxjs';
import {UsersCounters, UsersCountersResponse} from '../response/users/users-counters-response';
import {Injectable} from '@angular/core';
import {CitiesResponse} from '../response/cities/cities-response';

@Injectable()
export class UserApi {

  constructor(private http: HttpClient) {

  }

  public users(page: number, size: number): Observable<UsersResponse> {
    return this.http.get<UsersResponse>(environment.apiLink.concat(`users?page=${page}&size=${size}&lang=all&status=ALL`));
  }

  public statistics(): Observable<UsersCountersResponse> {
    return this.http.get<UsersCountersResponse>(environment.apiLink.concat(`users/statistics`));
  }

  public activate(id: string): Observable<UsersResponse> {
    return this.http.put<UsersResponse>(environment.apiLink.concat(`users/${id}/activate/?lang=all`), {});
  }


  public deactivate(id: string): Observable<UsersResponse> {
    return this.http.put<UsersResponse>(environment.apiLink.concat(`users/${id}/deactivate/?lang=all`), {});
  }
}
