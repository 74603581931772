import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PageHeaderInfoService {
  public icon = '';
  public title = '';
  public subTitle = '';

  public setData(icon: string, title: string, subTitle: string) {
    this.icon = icon;
    this.title = title;
    this.subTitle = subTitle;
  }

}
