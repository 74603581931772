import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {AdminResponse} from '../response/login/admin-response';
import {Observable} from 'rxjs';

@Injectable()
export class LoginApi {

  constructor(private http: HttpClient) {

  }


  public login(email: string, password: string): Observable<AdminResponse> {
    return this.http.post<AdminResponse>(environment.apiLink.concat('admins/login'), {username: email, password});
  }

}
