import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from '../ui/guest/login/login.component';
import {BaseAdminComponent} from '../ui/admin/base-admin/base-admin.component';
import {MustBeGuestGuard} from '../guard/must-be-guest-guard';
import {MustLoginGuard} from '../guard/must-login-guard';
import {AdminsComponent} from '../ui/admin/admins/admins.component';
import {SpecializationsComponent} from '../ui/admin/specializations/specializations.component';
import {CountriesComponent} from '../ui/admin/countries/countries.component';
import {CitiesComponent} from '../ui/admin/cities/cities.component';
import {NationalitiesComponent} from '../ui/admin/nationalities/nationalities.component';
import {ProfileComponent} from '../ui/admin/profile/profile.component';
import {CentersComponent} from '../ui/admin/centers/centers.component';
import {DoctorsComponent} from '../ui/admin/doctors/doctors.component';
import {BookingsComponent} from '../ui/admin/bookings/bookings.component';
import {UsersComponent} from '../ui/admin/users/users.component';
import {DashboardComponent} from '../ui/admin/dashboard/dashboard.component';

const routes: Routes = [
  {
    path: '', component: BaseAdminComponent, canActivate: [MustLoginGuard], children: [
      {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
      {path: 'dashboard', component: DashboardComponent},
      {path: 'admins', component: AdminsComponent},
      {path: 'specializations', component: SpecializationsComponent},
      {path: 'countries', component: CountriesComponent},
      {path: 'cities', component: CitiesComponent},
      {path: 'nationalities', component: NationalitiesComponent},
      {path: 'profile', component: ProfileComponent},
      {path: 'centers', component: CentersComponent},
      {path: 'doctors', component: DoctorsComponent},
      {path: 'bookings', component: BookingsComponent},
      {path: 'users', component: UsersComponent}
    ]
  },
  {
    path: 'guest/login', component: LoginComponent, canActivate: [MustBeGuestGuard]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
