import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {AdminsResponse} from '../response/admins/admins-response';
import {Observable} from 'rxjs';
import {BaseResponse} from '../response/base-response';
import {AdminResponse} from '../response/login/admin-response';
import {AdminsCountersResponse} from '../response/admins/admins-counters-response';

@Injectable()
export class AdminApi {

  constructor(private http: HttpClient) {

  }

  public admins(page: number, size: number): Observable<AdminsResponse> {
    return this.http.get<AdminsResponse>(environment.apiLink.concat(`admins?page=${page}&size=${size}`));
  }

  public activate(id: string): Observable<AdminResponse> {
    return this.http.put<AdminResponse>(environment.apiLink.concat(`admins/${id}/activate`), {});
  }

  public deActivate(id: string): Observable<AdminResponse> {
    return this.http.put<AdminResponse>(environment.apiLink.concat(`admins/${id}/deactivate`), {});
  }

  public changePassword(data): Observable<AdminResponse> {
    return this.http.post<AdminResponse>(environment.apiLink.concat('admins/passwords/change'), data);
  }

  public save(body: any): Observable<AdminsResponse> {
    return this.http.post<AdminsResponse>(environment.apiLink.concat(`admins`), body);
  }

  update(id: string, body: any): Observable<AdminsResponse> {
    return this.http.put<AdminsResponse>(environment.apiLink.concat(`admins/${id}`), body);
  }

  public statistics(): Observable<AdminsCountersResponse> {
    return this.http.get<AdminsCountersResponse>(environment.apiLink.concat(`admins/statistics`));
  }
}
