import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ToastrManager} from 'ng6-toastr-notifications';

@Injectable()
export class ToastService {

  constructor(private toaster: ToastrManager, private translator: TranslateService) {
  }

  public showErrorToast(message) {
    this.toaster.errorToastr(message);
  }
}
