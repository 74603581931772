import {Component, OnInit} from '@angular/core';
import {PageHeaderInfoService} from '../../../service/page-header-info-service';
import {AdminService} from '../../../service/admin-service';
import {Admin} from '../../../data/response/login/admin-response';
import {environment} from '../../../environments/environment';
import {DialogService} from '../../../service/dialog-service';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {LanguageService} from '../../../service/language-service';

declare var $;

@Component({
  selector: 'app-base-admin',
  templateUrl: './base-admin.component.html',
  styleUrls: ['./base-admin.component.css']
})
export class BaseAdminComponent implements OnInit {
  admin: Admin;
  placeholderImage = 'assets/images/ic_user_placeholder.png';

  constructor(public headerInfo: PageHeaderInfoService,
              private adminService: AdminService,
              private dialogService: DialogService,
              private translator: TranslateService,
              private languageService: LanguageService,
              private router: Router
  ) {
    this.admin = adminService.getAdmin();
  }

  ngOnInit() {
    $('.br-sideleft').on('click', '.br-menu-link', function(){
      // tslint:disable-next-line:prefer-const prefer-const
      var nextElem = $(this).next();
      var thisLink = $(this);

      if(nextElem.hasClass('br-menu-sub')) {

        if(nextElem.is(':visible')) {
          thisLink.removeClass('show-sub');
          nextElem.slideUp();
        } else {
          $('.br-menu-link').each(function(){
            $(this).removeClass('show-sub');
          });

          $('.br-menu-sub').each(function(){
            $(this).slideUp();
          });

          thisLink.addClass('show-sub');
          nextElem.slideDown();
        }
        return false;
      }
    });
  }

  getAdminImage() {
    return environment.baseLink.concat(this.admin.avatar);
  }

  signOutClick() {
    this.translator.get('dialog.sure_log_out').subscribe(value => {
      this.dialogService.confirmationWithTitle(value, () => {
        this.adminService.removeAdmin();
        this.router.navigateByUrl('guest/login');
      });
    });
  }

  changeLanguage() {
    this.languageService.changeLanguage();
  }

  editProfile() {
    this.router.navigate(['/profile']);
  }

}
