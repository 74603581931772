import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {SpecializationsResponse} from '../response/specializations/specializations-response';
import {Observable} from 'rxjs';
import {BaseResponse} from '../response/base-response';
import {SpecializationsCountersResponse} from '../response/specializations/specializations-counters-response';

@Injectable()
export class SpecializationApi {

  constructor(private http: HttpClient) {

  }

  public specializations(page: number, size: number): Observable<SpecializationsResponse> {
    return this.http.get<SpecializationsResponse>(environment.apiLink.concat(`specialities?page=${page}&size=${size}&lang=all&status=ALL`));
  }

  public activeSpecializations(page: number, size: number): Observable<SpecializationsResponse> {
    return this.http.get<SpecializationsResponse>(environment.apiLink.concat(`specialities?page=${page}&size=${size}&lang=all`));
  }

  public save(data): Observable<SpecializationsResponse> {
    return this.http.post<SpecializationsResponse>(environment.apiLink.concat('specialities?lang=all'), data);
  }

  public update(id: string, data) {
    return this.http.put<SpecializationsResponse>(environment.apiLink.concat(`specialities/${id}/?lang=all`), data);
  }


  public activate(id: string) {
    return this.http.put<SpecializationsResponse>(environment.apiLink.concat(`specialities/${id}/activate?lang=all`), {});
  }

  public deActivate(id: string) {
    return this.http.put<SpecializationsResponse>(environment.apiLink.concat(`specialities/${id}/deactivate?lang=all`), {});
  }


  public delete(id: string): Observable<BaseResponse> {
    return this.http.delete<BaseResponse>(environment.apiLink.concat(`specialities/${id}`), {});
  }


  public statistics(): Observable<SpecializationsCountersResponse> {
    return this.http.get<SpecializationsCountersResponse>(environment.apiLink.concat(`specialities/statistics`));
  }


}
