import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {AdminApi} from '../../data/api/admin-api';
import {DialogService} from '../../service/dialog-service';
import {ModalService} from '../../service/modal-service';

@Component({
  selector: 'app-change-password-component',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  password: { oldPassword: string, newPassword: string, confirmedPassword: string, submitForm: boolean };
  adminId: string;

  constructor(private adminApi: AdminApi, private dialogService: DialogService, private modalService: ModalService) {
  }

  ngOnInit() {
    this.setDefaultData();
  }

  setAdminId(adminId: string) {
    this.adminId = adminId;
  }

  changePassword(passwordForm: NgForm) {
    this.password.submitForm = true;
    if (!passwordForm.valid || this.password.newPassword !== this.password.confirmedPassword) {
      return;
    }
    // call APi.
    const body: any = {};
    body.oldPassword = this.password.oldPassword;
    body.newPassword = this.password.newPassword;
    body.confirmNewPassword = this.password.confirmedPassword;
    this.adminApi.changePassword(body)
      .subscribe(res => {
        this.close();
        this.dialogService.successMessage(res.message);
      });
  }

  public open() {
    this.setDefaultData();
    this.modalService.open('passwordModal');
  }

  public close() {
    this.modalService.close('passwordModal');
  }

  private setDefaultData() {
    this.password = {confirmedPassword: '', newPassword: '', oldPassword: '', submitForm: false};
  }
}
