import {Component, OnInit, ViewChild} from '@angular/core';
import {City} from '../../../data/response/cities/cities-response';
import {ModalService} from '../../../service/modal-service';
import {DialogService} from '../../../service/dialog-service';
import {CityApi} from '../../../data/api/city-api';
import {CountryApi} from '../../../data/api/country-api';
import {NgForm} from '@angular/forms';
import {EditImageComponent} from '../../../component/edit-image/edit-image.component';
import {PageHeaderInfoService} from '../../../service/page-header-info-service';
import {Country} from '../../../data/response/countries/countries-response';
import {CitiesCounters} from '../../../data/response/cities/cities-counters-response';

@Component({
  selector: 'app-cities',
  templateUrl: './cities.component.html',
  styleUrls: ['./cities.component.css']
})
export class CitiesComponent implements OnInit {

  @ViewChild('editImageComponent')
  editImageComponent: EditImageComponent;
  cities: City [] = [];
  countries: Country [] = [];
  selectedCountry = '';
  city: { nameAr: string, nameEn: string, id: string, index: number, submitForm: boolean };
  showCounters = false;
  citiesCounters: CitiesCounters;

  constructor(private modalService: ModalService, private dialogService: DialogService,
              private cityApi: CityApi, private countryApi: CountryApi,
              private headerInfoService: PageHeaderInfoService) {
    this.headerInfoService.setData('fa-building', 'app.cities', '');
  }

  ngOnInit() {
    this.setDefaultCity();
    this.loadAllActiveCities();
    this.loadAllCountries();
    this.loadCitiesCounters();
  }

  addCityClick() {
    if (this.selectedCountry === '') {
      this.dialogService.showErrorMessage('errors.select_country');
    } else {
      this.setDefaultCity();
      this.modalService.open('cityModal');
    }
  }

  private setDefaultCity() {
    this.city = {id: '', index: -1, nameAr: '', nameEn: '', submitForm: false};
  }

  changeCityActiveClick(i: number, cit: City) {
    // check if city is active or not.
    if (cit.isActive) {
      // deactivate.
      this.cityApi.deactivate(cit._id).subscribe(res => {
        cit.isActive = false;
        this.dialogService.successMessage(res.message);
      });
    } else {
      // activate.
      this.cityApi.activate(cit._id).subscribe(res => {
        cit.isActive = true;
        this.dialogService.successMessage(res.message);
      });
    }
  }

  editCityClick(i: number, cit: City) {
    this.city = {id: cit._id, index: i, nameEn: cit.name.en, nameAr: cit.name.ar, submitForm: false};
    this.modalService.open('cityModal');
  }

  saveCityModalClick(form: NgForm) {
    this.city.submitForm = true;
    if (!form.valid || this.selectedCountry === '') {
      return;
    }
    // call api.
    const body: any = {};
    body.name = {};
    body.name.ar = this.city.nameAr;
    body.name.en = this.city.nameEn;
    body.countryId = this.selectedCountry;
    // check if add or edit city.
    if (this.city.index > -1) {
      // edit
      this.cityApi.update(this.city.id, body).subscribe(res => {
        const cit: City = this.cities[this.city.index];
        cit.name = res.data[0].name;
        this.modalService.close('cityModal');
        this.dialogService.successMessage(res.message);
        this.setDefaultCity();
      });
    } else {
      // add
      this.cityApi.save(body).subscribe(res => {
        this.cities.push(res.data[0]);
        this.modalService.close('cityModal');
        this.dialogService.successMessage(res.message);
        this.setDefaultCity();
      });
    }
  }

  private loadAllActiveCities() {
    this.cityApi.allActiveCities().subscribe(res => {
      this.cities = res.data;
    });
  }


  public loadCountryCities(countryId: string) {
    this.selectedCountry = countryId;
    if (countryId === '') {
      this.loadAllActiveCities();
    } else {
      this.cityApi.cities(countryId, 1, 10000).subscribe(res => {
        this.cities = res.data;
      });
    }
  }

  deleteCityClick(i: number, cit: City) {
    this.dialogService.showDeleteItem(() => {
      this.cityApi.delete(cit._id).subscribe(res => {
        this.cities.splice(i, 1);
        this.dialogService.successMessage();
      });
    });
  }

  private loadAllCountries() {
    this.countryApi.activeCountries(1, 10000).subscribe(res => {
      this.countries = res.data;
    });
  }

  private loadCitiesCounters() {
    this.cityApi.statistics().subscribe(res => {
      this.citiesCounters = res.data[0];
    });
  }
}
