import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {CitiesResponse} from '../response/cities/cities-response';
import {Observable} from 'rxjs';
import {BaseResponse} from '../response/base-response';
import {CitiesCountersResponse} from '../response/cities/cities-counters-response';

@Injectable()
export class CityApi {

  constructor(private http: HttpClient) {

  }

  public cities(countryId: string, page: number, size: number): Observable<CitiesResponse> {
    return this.http.get<CitiesResponse>(environment.apiLink
      .concat(`cities?countryId=${countryId}&page=${page}&size=${size}&lang=all&status=ALL`));
  }

  public activeCities(countryId: string, page: number, size: number): Observable<CitiesResponse> {
    return this.http.get<CitiesResponse>(environment.apiLink
      .concat(`cities?countryId=${countryId}&page=${page}&size=${size}&lang=all`));
  }

  public allActiveCities(): Observable<CitiesResponse> {
    return this.http.get<CitiesResponse>(environment.apiLink
      .concat(`cities/all_active_cities`));
  }


  public save(data): Observable<CitiesResponse> {
    return this.http.post<CitiesResponse>(environment.apiLink.concat(`cities?lang=all`), data);
  }


  public update(id: string, data): Observable<CitiesResponse> {
    return this.http.put<CitiesResponse>(environment.apiLink.concat(`cities/${id}/?lang=all`), data);
  }


  public activate(id: string): Observable<CitiesResponse> {
    return this.http.put<CitiesResponse>(environment.apiLink.concat(`cities/${id}/activate/?lang=all`), {});
  }


  public deactivate(id: string): Observable<CitiesResponse> {
    return this.http.put<CitiesResponse>(environment.apiLink.concat(`cities/${id}/deactivate/?lang=all`), {});
  }

  public delete(id: string): Observable<BaseResponse> {
    return this.http.delete<BaseResponse>(environment.apiLink.concat(`cities/${id}`), {});
  }

  public statistics(): Observable<CitiesCountersResponse> {
    return this.http.get<CitiesCountersResponse>(environment.apiLink.concat(`cities/statistics`));
  }

}
