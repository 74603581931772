import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AdminService} from '../service/admin-service';
import {Injectable} from '@angular/core';

@Injectable()
export class MustLoginGuard implements CanActivate {

  constructor(private adminService: AdminService, private router: Router) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.adminService.getAdmin()) {
      // admin is register.
      return true;
    } else {
      // no admin found.
      this.router.navigateByUrl('guest/login');
      return false;
    }
  }

}
