import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {UploadImageResponse} from '../response/upload-image/upload-image-response';
import {Observable} from 'rxjs';

@Injectable()
export class UploadImageApi {

  constructor(private http: HttpClient) {

  }

  public uploadImage(id: string, type: string, data): Observable<UploadImageResponse> {
    return this.http.post<UploadImageResponse>(environment.apiLink.concat(`avatars/${id}?type=${type}`), data);
  }
}
