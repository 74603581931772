import {Injectable, NgZone, Renderer2} from '@angular/core';

declare var $;

@Injectable(
  {
    providedIn: 'root'
  }
)
export class CommonService {

  constructor(private ngZone: NgZone) {

  }

  showLoader() {
    this.ngZone.runOutsideAngular(() => {
      const loader: HTMLElement = $('#circle');
      if (loader) {
        $(loader).show();
      }

    });
  }

  hideLoader() {
    this.ngZone.runOutsideAngular(() => {
      const loader: HTMLElement = $('#circle');
      if (loader) {
        $(loader).hide();
      }
    });
  }
}

