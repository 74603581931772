import {Component, OnInit} from '@angular/core';
import {Booking} from '../../../data/response/bookings/bookings-response';
import {BookingApi} from '../../../data/api/booking-api';
import {DialogService} from '../../../service/dialog-service';
import {ModalService} from '../../../service/modal-service';
import {PageHeaderInfoService} from '../../../service/page-header-info-service';
import {BookingsCounters} from '../../../data/response/bookings/bookings-counters-response';
import {CenterApi} from '../../../data/api/center-api';
import {DoctorApi} from '../../../data/api/doctor-api';
import {Center} from '../../../data/response/centers/centers-response';
import {Doctor} from '../../../data/response/doctors/doctors-response';
import {formatDate} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.css']
})
export class BookingsComponent implements OnInit {

  bookings: Booking [] = [];
  bookingsCounters: BookingsCounters;
  showCounters = false;
  centers: Center[] = [];
  doctors: Doctor [] = [];
  searchBy: { centerId: string, doctorId: string, bookingDate: string, creatingDate: string, phone: string, bookingStatus: string };


  constructor(private bookingApi: BookingApi, private dialogService: DialogService,
              private modalService: ModalService, private headerService: PageHeaderInfoService,
              private centerApi: CenterApi, private doctorApi: DoctorApi, private translator: TranslateService) {
    this.headerService.setData('fa-coins', 'app.bookings', '');
  }

  ngOnInit() {
    this.loadBookingsCounters();
    this.loadCenters();
    this.loadDoctors();
    this.setDefaultSearchBy();
    this.searchForBookings();
  }

  confirmBooking(i: number, book: Booking) {
    this.translator.get('dialog.confirm_booking').subscribe(value => {
      this.dialogService.confirmationWithTitle(value, () => {
        this.bookingApi.confirm(book._id).subscribe(res => {
          this.dialogService.successMessage(res.message);
          book.status = 'CONFIRMED';
        });
      });
    });
  }

  cancelBooking(i: number, book: Booking) {
    this.translator.get('dialog.cancel_booking').subscribe(value => {
      this.dialogService.confirmationWithTitle(value, () => {
        this.bookingApi.cancel(book._id).subscribe(res => {
          this.dialogService.successMessage(res.message);
          book.status = 'CANCELLED';
        });
      });
    });
  }

  private loadBookingsCounters() {
    this.bookingApi.statistics().subscribe(res => {
      this.bookingsCounters = res.data[0];
    });
  }

  private loadCenters() {
    this.centerApi.centers(1, 1000000).subscribe(res => {
      this.centers = res.data;
    });
  }

  private loadDoctors() {
    this.doctorApi.doctors(1, 10000000).subscribe(res => {
      this.doctors = res.data;
    });
  }

  private setDefaultSearchBy() {
    this.searchBy = {bookingDate: '', bookingStatus: '', centerId: '', creatingDate: '', doctorId: '', phone: ''};
  }

  clearSearchCriteria() {
    this.setDefaultSearchBy();
  }

  public searchForBookings() {
    let criteria = '';
    let appendWhere = false;
    if (this.searchBy.bookingStatus) {
      if (appendWhere) {
        criteria += '&bookingStatus=' + this.searchBy.bookingStatus;
      } else {
        criteria += '?bookingStatus=' + this.searchBy.bookingStatus;
        appendWhere = true;
      }
    }

    if (this.searchBy.creatingDate) {
      const date = formatDate(this.searchBy.creatingDate, 'dd/MM/yyyy', 'en-US');
      if (appendWhere) {
        criteria += '&creatingDate=' + date;
      } else {
        criteria += '?creatingDate=' + date;
        appendWhere = true;
      }
    }

    if (this.searchBy.bookingDate) {
      const date = formatDate(this.searchBy.bookingDate, 'dd/MM/yyyy', 'en-US');
      if (appendWhere) {
        criteria += '&bookingDate=' + date;
      } else {
        criteria += '?bookingDate=' + date;
        appendWhere = true;
      }
    }

    if (this.searchBy.doctorId) {
      if (appendWhere) {
        criteria += '&doctorId=' + this.searchBy.doctorId;
      } else {
        criteria += '?doctorId=' + this.searchBy.doctorId;
        appendWhere = true;
      }
    }

    if (this.searchBy.centerId) {
      if (appendWhere) {
        criteria += '&centerId=' + this.searchBy.centerId;
      } else {
        criteria += '?centerId=' + this.searchBy.centerId;
        appendWhere = true;
      }
    }

    if (this.searchBy.phone) {
      if (appendWhere) {
        criteria += '&phone=' + this.searchBy.phone;
      } else {
        criteria += '?phone=' + this.searchBy.phone;
        appendWhere = true;
      }
    }

    if (appendWhere) {
      criteria += '&page=1&size=10000000';
    } else {
      criteria += '?page=1&size=10000000';
    }

    this.bookingApi.bookings(criteria).subscribe(res => {
      this.bookings = res.data;
    });
  }
}
