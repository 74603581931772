import {Component, OnInit} from '@angular/core';
import {environment} from '../../environments/environment';
import {UploadImageApi} from '../../data/api/upload-image-api';
import {ModalService} from '../../service/modal-service';
import {DialogService} from '../../service/dialog-service';

@Component({
  selector: 'app-edit-image',
  templateUrl: './edit-image.component.html',
  styleUrls: ['./edit-image.component.css']
})
export class EditImageComponent implements OnInit {

  image: any = null;
  imageString: string;
  uploadClick = false;
  imageId: string;
  callBack: any;
  type: string;

  constructor(private uploadImageApi: UploadImageApi,
              private modalService: ModalService, private dialogService: DialogService) {
  }

  ngOnInit() {
  }

  // tslint:disable-next-line:variable-name ban-types
  public setImage(imageLink: string, imageId: string, type: string, callBack: (link) => void) {
    this.imageString = environment.baseLink.concat(imageLink);
    this.imageId = imageId;
    this.callBack = callBack;
    this.type = type;
  }

  public open() {
    this.modalService.open('imageModal');
  }

  public close() {
    this.modalService.close('imageModal');
  }

  selectImage($event) {
    this.uploadClick = false;
    this.image = $event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL($event.target.files[0]); // read file as data url
    reader.onload = (event: any) => { // called once readAsDataURL is completed
      this.imageString = event.target.result;
    };
  }

  uploadImage() {
    this.uploadClick = true;
    if (this.image == null) {
      return;
    }
    // upload image.
    const data = new FormData();
    data.append('avatar', this.image);
    this.uploadImageApi.uploadImage(this.imageId, this.type, data)
      .subscribe(res => {
        this.callBack(res.data[0].avatar);
        this.dialogService.successMessage(res.message);
        this.close();
      });
  }
}
