import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AdminDashboardCounters} from '../response/dashboard/admin-dashboard-counters';
import {environment} from '../../environments/environment';


@Injectable()
export class DashboardApi {

  constructor(private http: HttpClient) {

  }

  public superAdminDashboard(filterDate = null): Observable<AdminDashboardCounters> {
    let link = environment.apiLink.concat(`dashboard/super_admin_dashboard`);
    if (filterDate != null && filterDate !== '') {
      link = link.concat('?filterDate=').concat(filterDate);
    }
    return this.http.get<AdminDashboardCounters>(link);
  }

  public centerAdminDashboard(filterDate = null): Observable<AdminDashboardCounters> {
    let link = environment.apiLink.concat(`dashboard/center_admin_dashboard`);
    if (filterDate != null && filterDate !== '') {
      link = link.concat('?filterDate=').concat(filterDate);
    }
    return this.http.get<AdminDashboardCounters>(link);
  }

  public nurseAdminDashboard(filterDate = null): Observable<AdminDashboardCounters> {
    let link = environment.apiLink.concat(`dashboard/nurse_admin_dashboard`);
    if (filterDate != null && filterDate !== '') {
      link = link.concat('?filterDate=').concat(filterDate);
    }
    return this.http.get<AdminDashboardCounters>(link);
  }
}
