import {Pipe, PipeTransform} from '@angular/core';
import {LanguageService} from '../service/language-service';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'booking_status',
  pure: false
})
export class BookingStatusPipe implements PipeTransform {


  constructor(private translator: TranslateService) {
  }

  transform(value: string, ...args: any[]): any {
    return this.translator.instant('booking_status.' + value.toLowerCase());
  }

}
