import {Component, OnInit, ViewChild} from '@angular/core';
import {Admin} from '../../../data/response/login/admin-response';
import {AdminApi} from '../../../data/api/admin-api';
import {environment} from '../../../environments/environment';
import {CenterApi} from '../../../data/api/center-api';
import {Center} from '../../../data/response/centers/centers-response';
import {NgForm} from '@angular/forms';
import {ModalService} from '../../../service/modal-service';
import {PageHeaderInfoService} from '../../../service/page-header-info-service';
import {DialogService} from '../../../service/dialog-service';
import {EditImageComponent} from '../../../component/edit-image/edit-image.component';
import {ChangePasswordComponent} from '../../../component/change-password-component/change-password.component';
import {AdminCountrs, AdminsCountersResponse} from '../../../data/response/admins/admins-counters-response';

declare var $;

@Component({
  selector: 'app-admins',
  templateUrl: './admins.component.html',
  styleUrls: ['./admins.component.css']
})
export class AdminsComponent implements OnInit {

  @ViewChild('editImageComponent')
  editImageComponent: EditImageComponent;
  @ViewChild('changePasswordComponentComponent')
  changePasswordComponentComponent: ChangePasswordComponent;

  admins: Admin[] = [];
  centers: Center[] = [];
  adminCounters: AdminCountrs;
  showCountersView = false;

  admin: {
    fullName: string, email: string, type: string, center: string, id: string,
    index: number, submitForm: boolean, phone: string, password: string
  };
  oldAdminData: {
    fullName: string, email: string, type: string, center: string, id: string, phone: string
  };


  constructor(private adminApi: AdminApi, private centerApi: CenterApi, private modalService: ModalService,
              private headerInfoService: PageHeaderInfoService,
              private dialogService: DialogService) {
    headerInfoService.setData('fa-users', 'app.admins', '');
  }

  ngOnInit() {
    this.loadCenters();
    this.loadAdmins();
    this.loadAdminStatistics();
    this.setDefaultAdmin();
  }

  private loadAdmins() {
    this.adminApi.admins(1, 1000000)
      .subscribe(res => {
        this.admins = res.data;
      });
  }

  getAdminImage(avatar: string) {
    return environment.baseLink.concat(avatar);
  }

  changeAdminActivationClick(i: number, admin: Admin) {
    if (admin.isActive) {
      // deactivate.
      this.adminApi.deActivate(admin._id).subscribe(res => {
        admin.isActive = false;
        this.dialogService.successMessage(res.message);
      });
    } else {
      // activate.
      this.adminApi.activate(admin._id).subscribe(res => {
        admin.isActive = true;
        this.dialogService.successMessage(res.message);
      });
    }
  }

  editAdminClick(i: number, admin: Admin) {
    this.admin = {
      id: admin._id, submitForm: false, center: '',
      type: admin.adminType, phone: admin.phone, password: '', fullName: admin.fullName, index: i, email: admin.email
    };
    this.oldAdminData = {
      center: this.admin.center, email: this.admin.email,
      fullName: this.admin.fullName, id: this.admin.id, phone: this.admin.phone, type: this.admin.type
    };
    this.modalService.open('adminModal');
  }

  private setDefaultAdmin() {
    this.admin = {
      center: '', email: '', fullName: '', id: '', index: -1,
      submitForm: false, type: 'SUPER_ADMIN', phone: '', password: ''
    };
    this.oldAdminData = {
      center: '', email: '', fullName: '', id: '', type: 'SUPER_ADMIN', phone: ''
    };
  }

  private loadCenters() {
    this.centerApi.centers(1, 100000).subscribe(res => {
      this.centers = res.data;
    });
  }

  saveAdmin(adminForm: NgForm) {
    this.admin.submitForm = true;
    if (!adminForm.valid || (this.admin.type !== 'SUPER_ADMIN' && this.admin.center === '')) {
      return;
    }

    // call APi.
    if (this.admin.id !== '') {
      // edit.
      const body = this.getChangedAdminData();
      this.adminApi.update(this.admin.id, body).subscribe(res => {
        this.admins[this.admin.index] = res.data[0];
        this.modalService.close('adminModal');
        this.dialogService.successMessage(res.message);
        this.setDefaultAdmin();
      });
    } else {
      // add admin.
      const body: any = {};
      body.fullName = this.admin.fullName;
      body.phone = this.admin.phone;
      body.email = this.admin.email;
      body.adminType = this.admin.type;
      body.password = this.admin.password;
      if (this.admin.center !== '') {
        body.centerId = this.admin.center;
      }
      this.adminApi.save(body).subscribe(res => {
        this.admins.push(res.data[0]);
        this.modalService.close('adminModal');
        this.dialogService.successMessage(res.message);
        this.setDefaultAdmin();
      });
    }
  }

  addAdminClick() {
    this.setDefaultAdmin();
    this.modalService.open('adminModal');
  }

  editImageClick(i: number, admin: Admin) {
    this.editImageComponent.setImage(admin.avatar, admin._id, 'admin', link => {
      admin.avatar = link;
    });
    this.editImageComponent.open();
  }

  private getChangedAdminData() {
    const body: any = {};
    if (this.oldAdminData.fullName !== this.admin.fullName) {
      body.fullName = this.admin.fullName;
    }

    if (this.oldAdminData.email !== this.admin.email) {
      body.email = this.admin.email;
    }

    if (this.oldAdminData.phone !== this.admin.phone) {
      body.phone = this.admin.phone;
    }

    if (this.oldAdminData.type !== this.admin.type) {
      body.adminType = this.admin.type;
    }

    if (this.admin.type !== 'SUPER_ADMIN') {
      body.centerId = this.admin.center;
    }
    return body;
  }

  changePasswordClick(i: number, admin: Admin) {
    this.changePasswordComponentComponent.setAdminId(admin._id);
    this.changePasswordComponentComponent.open();
  }

  private loadAdminStatistics() {
    this.adminApi.statistics().subscribe(res => {
      this.adminCounters = res.data[0];
    });
  }
}
