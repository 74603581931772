import {Component, OnInit} from '@angular/core';
import {DashboardApi} from '../../../data/api/dashboard-api';
import {Admin} from '../../../data/response/login/admin-response';
import {AdminService} from '../../../service/admin-service';
import {Counters} from '../../../data/response/dashboard/admin-dashboard-counters';
import {formatDate} from '@angular/common';
import {PageHeaderInfoService} from '../../../service/page-header-info-service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  admin: Admin;
  filterDate = '';
  counters: Counters;

  constructor(private dashboardApi: DashboardApi, private adminService: AdminService
    ,         private headerService: PageHeaderInfoService) {
    this.admin = adminService.getAdmin();
    headerService.setData('fa-chart-line', 'app.dashboard', '');

  }

  ngOnInit() {
    if (this.admin.adminType === 'SUPER_ADMIN') {
      this.loadSuperAdminCounters();
    } else if (this.admin.adminType === 'ADMIN') {
      this.loadCenterAdminCounters();
    }
  }

  private loadSuperAdminCounters() {
    let date = '';
    if (this.filterDate) {
      date = formatDate(this.filterDate, 'dd/MM/yyyy', 'en-US');
    }
    this.dashboardApi.superAdminDashboard(date).subscribe(res => {
      this.counters = res.data[0];
    });
  }

  private loadCenterAdminCounters() {
    let date = '';
    if (this.filterDate) {
      date = formatDate(this.filterDate, 'dd/MM/yyyy', 'en-US');
    }
    this.dashboardApi.centerAdminDashboard(date).subscribe(res => {
      this.counters = res.data[0];
    });
  }

  private loadNurseAdminCounters() {
    let date = '';
    if (this.filterDate) {
      date = formatDate(this.filterDate, 'dd/MM/yyyy', 'en-US');
    }
    this.dashboardApi.nurseAdminDashboard(date).subscribe(res => {
      this.counters = res.data[0];
    });
  }
}
