import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {NationalitiesResponse} from '../response/nationalities/nationalities-response';
import {BaseResponse} from '../response/base-response';
import {NationalitiesCountersResponse} from '../response/nationalities/nationalities-counters-response';

@Injectable()
export class NationalityApi {

  constructor(private http: HttpClient) {

  }

  public nationalities(page: number, size: number): Observable<NationalitiesResponse> {
    return this.http.get<NationalitiesResponse>(environment.apiLink.concat(`nationalities?page=${page}&size=${size}&lang=all&status=ALL`));
  }

  public activeNationalities(page: number, size: number): Observable<NationalitiesResponse> {
    return this.http.get<NationalitiesResponse>(environment.apiLink
      .concat(`nationalities?page=${page}&size=${size}&lang=all&status=ALL`));
  }

  public save(data): Observable<NationalitiesResponse> {
    return this.http.post<NationalitiesResponse>(environment.apiLink.concat(`nationalities?lang=all`), data);
  }

  public update(id: string, data): Observable<NationalitiesResponse> {
    return this.http.put<NationalitiesResponse>(environment.apiLink.concat(`nationalities/${id}/?lang=all`), data);
  }

  public activate(id: string): Observable<NationalitiesResponse> {
    return this.http.put<NationalitiesResponse>(environment.apiLink.concat(`nationalities/${id}/activate/?lang=all`), {});
  }

  public deActivate(id: string): Observable<NationalitiesResponse> {
    return this.http.put<NationalitiesResponse>(environment.apiLink.concat(`nationalities/${id}/deactivate/?lang=all`), {});
  }

  public delete(id: string): Observable<BaseResponse> {
    return this.http.delete<BaseResponse>(environment.apiLink.concat(`nationalities/${id}`), {});
  }

  public statistics(): Observable<NationalitiesCountersResponse> {
    return this.http.get<NationalitiesCountersResponse>(environment.apiLink.concat(`nationalities/statistics`));
  }

}
