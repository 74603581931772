import {Injectable} from '@angular/core';
import {Admin} from '../data/response/login/admin-response';

@Injectable(
  {
    providedIn: 'root'
  }
)
export class AdminService {
  admin: Admin;

  constructor() {

  }

  setAdmin(admin: Admin, saveInCache: boolean) {
    this.admin = admin;
    if (saveInCache) {
      localStorage.setItem('admin', JSON.stringify(admin));
    }
  }

  getAdmin() {
    if (this.admin == null) {
      const adminInCache = localStorage.getItem('admin');
      if (adminInCache) {
        this.admin = JSON.parse(adminInCache);
      }
    }
    return this.admin;
  }

  removeAdmin() {
    this.admin = null;
    localStorage.removeItem('admin');
  }


}
