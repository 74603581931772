import {Component, OnInit, ViewChild} from '@angular/core';
import {DialogService} from '../../../service/dialog-service';
import {ModalService} from '../../../service/modal-service';
import {NationalityApi} from '../../../data/api/nationality-api';
import {Nationality} from '../../../data/response/nationalities/nationalities-response';
import {environment} from '../../../environments/environment';
import {NgForm} from '@angular/forms';
import {EditImageComponent} from '../../../component/edit-image/edit-image.component';
import {PageHeaderInfoService} from '../../../service/page-header-info-service';
import {NationalitiesCounters} from '../../../data/response/nationalities/nationalities-counters-response';

@Component({
  selector: 'app-nationalities',
  templateUrl: './nationalities.component.html',
  styleUrls: ['./nationalities.component.css']
})
export class NationalitiesComponent implements OnInit {

  // GUI.
  @ViewChild('editImageComponent')
  editImageComponent: EditImageComponent;
  // Data
  nationality: { nameAr: string, nameEn: string, id: string, index: number, submitForm: boolean };
  nationalities: Nationality [] = [];
  showCounters = false;
  nationalitiesCounters: NationalitiesCounters;

  constructor(private dialogService: DialogService, private modalService: ModalService,
              private nationalityApi: NationalityApi,
              private headerInfoService: PageHeaderInfoService) {
    this.headerInfoService.setData('fa-flag-usa', 'app.nationalities', '');
  }

  ngOnInit() {
    this.setDefaultNationality();
    this.loadAllNationalities();
    this.loadNationalitiesCounters();
  }

  addNationalityClick() {
    this.setDefaultNationality();
    this.modalService.open('nationalityModal');
  }

  private setDefaultNationality() {
    this.nationality = {id: '', index: -1, nameAr: '', nameEn: '', submitForm: false};
  }

  getNationalityImage(avatar: any) {
    return environment.baseLink.concat(avatar);
  }

  changeNationalityActiveClick(i: number, nat: Nationality) {
    // check if nationality is active or not.
    if (nat.isActive) {
      // deactivate
      this.nationalityApi.deActivate(nat._id).subscribe(res => {
        nat.isActive = false;
        this.dialogService.successMessage(res.message);
      });
    } else {
      // activate.
      this.nationalityApi.activate(nat._id).subscribe(res => {
        nat.isActive = true;
        this.dialogService.successMessage(res.message);
      });
    }
  }

  editImageClick(i: number, nat: Nationality) {
    this.editImageComponent.setImage(nat.avatar, nat._id, 'nationality', link => {
      nat.avatar = link;
    });
    this.editImageComponent.open();
  }

  editNationalityClick(i: number, nat: Nationality) {
    this.nationality = {
      submitForm: false,
      nameEn: nat.name.en,
      nameAr: nat.name.ar,
      index: i,
      id: nat._id
    };
    this.modalService.open('nationalityModal');
  }

  saveNationalityModalClick(form: NgForm) {
    this.nationality.submitForm = true;
    if (!form.valid) {
      return;
    }
    const body: any = {};
    body.name = {};
    body.name.ar = this.nationality.nameAr;
    body.name.en = this.nationality.nameEn;
    // check if add or edit .
    if (this.nationality.index > -1) {
      // edit.
      this.nationalityApi.update(this.nationality.id, body).subscribe(res => {
        const nat = this.nationalities[this.nationality.index];
        nat.name = res.data[0].name;
        this.modalService.close('nationalityModal');
        this.dialogService.successMessage(res.message);
        this.setDefaultNationality();
      });
    } else {
      // add
      this.nationalityApi.save(body).subscribe(res => {
        this.nationalities.push(res.data[0]);
        this.modalService.close('nationalityModal');
        this.dialogService.successMessage(res.message);
        this.setDefaultNationality();
      });
    }
  }

  private loadAllNationalities() {
    this.nationalityApi.nationalities(1, 10000).subscribe(res => {
      this.nationalities = res.data;
    });
  }

  deleteNationalityClick(i: number, nat: Nationality) {
    this.dialogService.showDeleteItem(() => {
      this.nationalityApi.delete(nat._id).subscribe(res => {
        this.nationalities.splice(i, 1);
        this.dialogService.successMessage();
      });
    });
  }

  private loadNationalitiesCounters() {
    this.nationalityApi.statistics().subscribe(res => {
      this.nationalitiesCounters = res.data[0];
    });
  }
}
