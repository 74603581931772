import {Directive, ElementRef, HostListener} from '@angular/core';


@Directive({selector: '[appEnglishOnly]'})
export class EnglishOnlyDirective {
  AllowedString = ' qwertyuioplkjhgfdsazxcvbnmQWERTYUIOPLKJHGFDSAZXCVBNM Backspace';
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-'];

  constructor(private el: ElementRef
  ) {

  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Allow Backspace, tab, end, and home keys

    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);
    if (this.AllowedString.indexOf(event.key) === -1
    ) {
      event.preventDefault();
    }
  }
}
