import {NgModule} from '@angular/core';
import {MustLoginGuard} from './must-login-guard';
import {MustBeGuestGuard} from './must-be-guest-guard';

@NgModule({
  providers: [MustLoginGuard, MustBeGuestGuard]
})
export class GuardModule {

}
