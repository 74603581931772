import {Injectable} from '@angular/core';

declare var $;

@Injectable()
export class ModalService {


  public open(id: string) {
    $(`#${id}`).modal('show');
  }

  public close(id: string) {
    $(`#${id}`).modal('hide');
  }
}
