import {Component, OnInit, ViewChild} from '@angular/core';
import {Country} from '../../../data/response/countries/countries-response';
import {ModalService} from '../../../service/modal-service';
import {DialogService} from '../../../service/dialog-service';
import {CountryApi} from '../../../data/api/country-api';
import {environment} from '../../../environments/environment';
import {NgForm} from '@angular/forms';
import {EditImageComponent} from '../../../component/edit-image/edit-image.component';
import {PageHeaderInfoService} from '../../../service/page-header-info-service';
import {CountryCounter} from '../../../data/response/countries/countries-counters-response';

@Component({
  selector: 'app-countries',
  templateUrl: './countries.component.html',
  styleUrls: ['./countries.component.css']
})
export class CountriesComponent implements OnInit {

  @ViewChild('editImageComponent')
  editImageComponent: EditImageComponent;
  placeholderImage = 'assets/images/ic_country.png';
  countries: Country[] = [];
  country: { nameAr: string, nameEn: string, id: string, index: number, submitForm: boolean };
  showCounters = false;
  countryCounters: CountryCounter;

  constructor(private modalService: ModalService, private dialogService: DialogService,
              private countryAPi: CountryApi, private headerInfoService: PageHeaderInfoService) {
    this.headerInfoService.setData('fa-flag', 'app.countries', '');
  }

  ngOnInit() {
    this.setDefaultCountry();
    this.loadCountries();
    this.loadStatistics();
  }

  addCountryClick() {
    this.setDefaultCountry();
    this.modalService.open('countryModal');
  }

  private setDefaultCountry() {
    this.country = {id: '', index: -1, nameAr: '', nameEn: '', submitForm: false};
  }

  private loadCountries() {
    this.countryAPi.countries(1, 10000).subscribe(res => {
      this.countries = res.data;
    });
  }

  getCountryImage(avatar: string) {
    return environment.baseLink.concat(avatar);
  }

  changeCountryActiveClick(i: number, coun: Country) {
    // check if country is active or not.
    if (coun.isActive) {
      // deactivate
      this.countryAPi.deActivate(coun._id).subscribe(res => {
        coun.isActive = false;
        this.dialogService.successMessage(res.message);
      });
    } else {
      // activate.
      this.countryAPi.activate(coun._id).subscribe(res => {
        coun.isActive = true;
        this.dialogService.successMessage(res.message);
      });
    }
  }

  editImageClick(i: number, coun: Country) {
    this.editImageComponent.setImage(coun.avatar, coun._id, 'country', (link) => {
      this.countries[i].avatar = link;
    });
    this.editImageComponent.open();
  }

  editCountryClick(i: number, coun: Country) {
    this.country = {submitForm: false, nameEn: coun.name.en, nameAr: coun.name.ar, index: i, id: coun._id};
    this.modalService.open('countryModal');
  }

  saveCountryModalClick(form: NgForm) {
    this.country.submitForm = true;
    if (!form.valid) {
      return;
    }
    const body: any = {};
    body.name = {};
    body.name.ar = this.country.nameAr;
    body.name.en = this.country.nameEn;
    // check if user add or edit country.
    if (this.country.index > -1) {
      // edit
      this.countryAPi.update(this.country.id, body)
        .subscribe(res => {
          const coun = this.countries[this.country.index];
          coun.name = res.data[0].name;
          this.modalService.close('countryModal');
          this.dialogService.successMessage(res.message);
          this.setDefaultCountry();
        });
    } else {
      // add
      this.countryAPi.save(body)
        .subscribe(res => {
          this.countries.push(res.data[0]);
          this.modalService.close('countryModal');
          this.dialogService.successMessage(res.message);
          this.setDefaultCountry();
        });
    }
  }

  private loadStatistics() {
    this.countryAPi.statistics().subscribe(res => {
      this.countryCounters = res.data[0];
    });
  }
}
