import {Injectable} from '@angular/core';
import {AdminService} from '../service/admin-service';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable()
export class MustBeGuestGuard implements CanActivate {

  constructor(private adminService: AdminService, private router: Router) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.adminService.getAdmin()) {
      this.router.navigateByUrl('admin');
      return false;
    }
    return true;
  }
}
