import {Component, OnInit} from '@angular/core';
import {UserApi} from '../../../data/api/user-api';
import {PageHeaderInfoService} from '../../../service/page-header-info-service';
import {DialogService} from '../../../service/dialog-service';
import {User} from '../../../data/response/users/users-response';
import {UsersCounters} from '../../../data/response/users/users-counters-response';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  users: User[] = [];
  usersCounters: UsersCounters;
  showCounters = false;

  constructor(private headerService: PageHeaderInfoService,
              private userApi: UserApi, private dialogService: DialogService) {
    headerService.setData('fa-male', 'app.users', '');

  }

  ngOnInit() {
    this.loadUsers();
    this.loadUsersCounters();
  }

  private loadUsers() {
    this.userApi.users(1, 10000000).subscribe(res => {
      this.users = res.data;
    });
  }

  private loadUsersCounters() {
    this.userApi.statistics().subscribe(res => {
      this.usersCounters = res.data[0];
    });
  }

  changeUserActiveClick(i: number, user: User) {
    // check if user is active or not,
    if (user.isActive) {
      // de activate
      this.userApi.deactivate(user._id).subscribe(res => {
        user.isActive = false;
        this.dialogService.successMessage(res.message);
      });
    } else {
      // activate.
      this.userApi.activate(user._id).subscribe(res => {
        user.isActive = true;
        this.dialogService.successMessage(res.message);
      });
    }
  }

  getUserImage(avatar: string) {
    return environment.baseLink.concat(avatar);
  }
}
