import {Component, OnInit, ViewChild} from '@angular/core';
import {DoctorApi} from '../../../data/api/doctor-api';
import {DialogService} from '../../../service/dialog-service';
import {ModalService} from '../../../service/modal-service';
import {Availablity, Doctor} from '../../../data/response/doctors/doctors-response';
import {environment} from '../../../environments/environment';
import {PageHeaderInfoService} from '../../../service/page-header-info-service';
import {EditImageComponent} from '../../../component/edit-image/edit-image.component';
import {SpecializationApi} from '../../../data/api/specialization-api';
import {CenterApi} from '../../../data/api/center-api';
import {NationalityApi} from '../../../data/api/nationality-api';
import {Specialization} from '../../../data/response/specializations/specializations-response';
import {Center} from '../../../data/response/centers/centers-response';
import {Nationality} from '../../../data/response/nationalities/nationalities-response';
import {NgForm} from '@angular/forms';
import {DoctorTimesComponent} from './doctor-times/doctor-times.component';
import {DoctorsCounters} from '../../../data/response/doctors/doctors-counters-response';

@Component({
  selector: 'app-doctors',
  templateUrl: './doctors.component.html',
  styleUrls: ['./doctors.component.css']
})
export class DoctorsComponent implements OnInit {

  @ViewChild('editImageComponent')
  editImageComponent: EditImageComponent;
  @ViewChild('doctorTimes')
  doctorTimes: DoctorTimesComponent;

  doctors: Doctor[] = [];
  specializations: Specialization[] = [];
  centers: Center[] = [];
  nationalities: Nationality[] = [];
  hospitals: Center[] = [];
  docNameAr: string;
  docNameEn: string;
  docTitleAr: string;
  docTitleEn: string;
  docBriefAr: string;
  docBriefEn: string;
  specialityId: string;
  centerId: string;
  nationalityId: string;
  examinationFees: number;
  availablity: any[];
  index: number;
  id: string;
  submitForm: boolean;
  day1Availability: boolean;
  day2Availability: boolean;
  day3Availability: boolean;
  day4Availability: boolean;
  day5Availability: boolean;
  day6Availability: boolean;
  day7Availability: boolean;

  searchBy: {
    type: string, speciality: string, centerId: string,
    nationality: string, centerName: string, validation: string
  };
  filterNationalities: Nationality [ ] = [];
  showCounters = false;
  doctorsCounters: DoctorsCounters;

  constructor(private headerService: PageHeaderInfoService, private doctorApi: DoctorApi,
              private dialogService: DialogService,
              private modalService: ModalService, private specialityAPi: SpecializationApi,
              private centersApi: CenterApi, private nationalityApi: NationalityApi, private hospitalApi: CenterApi) {
    headerService.setData('fa-stethoscope', 'app.doctors', '');
  }

  ngOnInit() {
    this.loadDoctors();
    this.loadSpecializations();
    this.loadNationalities();
    this.loadCenters();
    this.loadHospitals();
    this.setDefaultDoctorData();
    this.searchBy = {type: '0', centerName: '', centerId: '', nationality: '', speciality: '', validation: ''};
    this.loadDoctorsCounters();
  }

  addDoctorClick() {
    this.setDefaultDoctorData();
    this.modalService.open('doctorModal');
  }

  getDoctorImage(avatar: string) {
    return environment.baseLink.concat(avatar);
  }

  changeDoctorActivationClick(i: number, doc: Doctor) {
    // check if doctor is active or not.
    if (doc.isActive) {
      // deactivate
      this.doctorApi.deactivate(doc._id).subscribe(res => {
        doc.isActive = false;
        this.dialogService.successMessage(res.message);
      });
    } else {
      // activate
      this.doctorApi.activate(doc._id).subscribe(res => {
        doc.isActive = true;
        this.dialogService.successMessage(res.message);
      });
    }
  }

  editImageClick(i: number, doctor: Doctor) {
    this.editImageComponent.setImage(doctor.avatar, doctor._id, 'doctor', link => {
      doctor.avatar = link;
    });
    this.editImageComponent.open();
  }

  editDoctorClick(i: number, doc: Doctor) {
    this.setDefaultDoctorData();
    this.index = i;
    this.docNameAr = doc.name.ar;
    this.docNameEn = doc.name.en;
    this.docTitleAr = doc.title.ar;
    this.docTitleEn = doc.title.en;
    this.docBriefAr = doc.brief.ar;
    this.docBriefEn = doc.brief.en;
    this.specialityId = doc.speciality._id;
    this.nationalityId = doc.nationality._id;
    this.id = doc._id;
    this.centerId = doc.center._id;
    this.examinationFees = doc.examinationFees;
    // get doctor times.
    doc.availablity.forEach(value => {
      if (value.day === 'saturday') {
        const time = this.availablity[0];
        this.day1Availability = true;
        time.isAvailable = true;
        time.from = value.times[0].from;
        time.to = value.times[0].to;
        time.maxBookings = value.maxBookings;
      } else if (value.day === 'sunday') {
        const time = this.availablity[1];
        this.day2Availability = true;
        time.isAvailable = true;
        time.from = value.times[0].from;
        time.to = value.times[0].to;
        time.maxBookings = value.maxBookings;
      } else if (value.day === 'monday') {
        const time = this.availablity[2];
        this.day3Availability = true;
        time.isAvailable = true;
        time.from = value.times[0].from;
        time.to = value.times[0].to;
        time.maxBookings = value.maxBookings;
      } else if (value.day === 'tuesday') {
        const time = this.availablity[3];
        this.day4Availability = true;
        time.isAvailable = true;
        time.from = value.times[0].from;
        time.to = value.times[0].to;
        time.maxBookings = value.maxBookings;
      } else if (value.day === 'wednesday') {
        const time = this.availablity[4];
        this.day5Availability = true;
        time.isAvailable = true;
        time.from = value.times[0].from;
        time.to = value.times[0].to;
        time.maxBookings = value.maxBookings;
      } else if (value.day === 'thursday') {
        const time = this.availablity[5];
        this.day6Availability = true;
        time.isAvailable = true;
        time.from = value.times[0].from;
        time.to = value.times[0].to;
        time.maxBookings = value.maxBookings;
      } else if (value.day === 'friday') {
        const time = this.availablity[6];
        this.day7Availability = true;
        time.isAvailable = true;
        time.from = value.times[0].from;
        time.to = value.times[0].to;
        time.maxBookings = value.maxBookings;
      }
    });
    this.modalService.open('doctorModal');
  }

  private loadDoctors() {
    this.doctorApi.doctors(1, 10000).subscribe(res => {
      this.doctors = res.data;
    });
  }

  showContentInDialog(i: number, text: string) {
    const content = `<div class="text-muted h4">${text}</div>`;
    this.dialogService.showText(content);
  }

  private loadSpecializations() {
    this.specialityAPi.activeSpecializations(1, 1000).subscribe(res => {
      this.specializations = res.data;
    });
  }

  private loadCenters() {
    this.centersApi.activeCenters(1, 10000).subscribe(res => {
      this.centers = res.data;
    });
  }


  private loadHospitals() {
    this.hospitalApi.activeCenters(1, 10000).subscribe(res => {
      this.hospitals = res.data;
    });
  }

  private setDefaultDoctorData() {
    this.availablity = [{
      isAvailable: false,
      from: '10:00',
      to: '23:30',
      maxBookings: 10
    }, {
      isAvailable: false,
      from: '10:00',
      to: '23:30',
      maxBookings: 10
    }, {
      isAvailable: false,
      from: '10:00',
      to: '23:30',
      maxBookings: 10
    }, {
      isAvailable: false,
      from: '10:00',
      to: '23:30',
      maxBookings: 10
    }, {
      isAvailable: false,
      from: '10:00',
      to: '23:30',
      maxBookings: 10
    }, {
      isAvailable: false,
      from: '10:00',
      to: '23:30',
      maxBookings: 10
    }, {
      isAvailable: false,
      from: '10:00',
      to: '23:30',
      maxBookings: 10
    }];
    this.docNameAr = '';
    this.docNameEn = '';
    this.docTitleAr = '';
    this.docTitleEn = '';
    this.docBriefAr = '';
    this.docBriefEn = '';
    this.specialityId = '';
    this.centerId = '';
    this.nationalityId = '';
    this.examinationFees = 0;
    this.index = -1;
    this.id = '';
    this.submitForm = false;
    this.day1Availability = false;
    this.day2Availability = false;
    this.day3Availability = false;
    this.day4Availability = false;
    this.day5Availability = false;
    this.day6Availability = false;
    this.day7Availability = false;
  }

  saveDoctor(doctorForm: NgForm) {
    this.submitForm = true;
    if (!doctorForm.valid || this.nationalityId === '' || this.centerId === '' || this.specialityId === '') {
      return;
    }
    const body: any = {};
    body.name = {};
    body.name.ar = this.docNameAr;
    body.name.en = this.docNameEn;
    body.title = {};
    body.title.ar = this.docTitleAr;
    body.title.en = this.docTitleEn;
    body.brief = {};
    body.brief.ar = this.docBriefAr;
    body.brief.en = this.docBriefEn;
    body.specialityId = this.specialityId;
    body.centerId = this.centerId;
    body.nationalityId = this.nationalityId;
    body.examinationFees = this.examinationFees;
    body.availablity = this.getWorkingDays();
    // check if add or edit doctor.
    if (this.index > -1) {
      // edit
      this.doctorApi.update(this.id, body).subscribe(res => {
        this.modalService.close('doctorModal');
        this.doctors[this.index] = res.data[0];
        this.dialogService.successMessage(res.message);
        this.setDefaultDoctorData();
      });
    } else {
      // save.
      this.doctorApi.save(body).subscribe(res => {
        this.modalService.close('doctorModal');
        this.doctors.push(res.data[0]);
        this.dialogService.successMessage(res.message);
        this.setDefaultDoctorData();
      });
    }
  }

  changeDayAvailability(dayIndex: number, isAvailable: boolean) {
    this.availablity[dayIndex].isAvailable = isAvailable;
  }

  private getWorkingDays() {
    const days = [];
    this.availablity.forEach((value, index) => {
      if (index === 0 && value.isAvailable) {
        // saturady
        days.push({
          day: 'saturday',
          times: [
            {
              from: value.from,
              to: value.to
            }
          ],
          maxBookings: value.maxBookings
        });
      } else if (index === 1 && value.isAvailable) {
        // sunday
        days.push({
          day: 'sunday',
          times: [
            {
              from: value.from,
              to: value.to
            }
          ],
          maxBookings: value.maxBookings
        });
      } else if (index === 2 && value.isAvailable) {
        // monday
        days.push({
          day: 'monday',
          times: [
            {
              from: value.from,
              to: value.to
            }
          ],
          maxBookings: value.maxBookings
        });
      } else if (index === 3 && value.isAvailable) {
        // tuesday
        days.push({
          day: 'tuesday',
          times: [
            {
              from: value.from,
              to: value.to
            }
          ],
          maxBookings: value.maxBookings
        });
      } else if (index === 4 && value.isAvailable) {
        // wednesday
        days.push({
          day: 'wednesday',
          times: [
            {
              from: value.from,
              to: value.to
            }
          ],
          maxBookings: value.maxBookings
        });
      } else if (index === 5 && value.isAvailable) {
        // thursday
        days.push({
          day: 'thursday',
          times: [
            {
              from: value.from,
              to: value.to
            }
          ],
          maxBookings: value.maxBookings
        });
      } else if (index === 6 && value.isAvailable) {
        // friday
        days.push({
          day: 'friday',
          times: [
            {
              from: value.from,
              to: value.to
            }
          ],
          maxBookings: value.maxBookings
        });
      }
    });
    return days;
  }

  /*  private convertTo12Hour(time: string) {
      const H = +time.substr(0, 2);
      let h: string | number = (H % 12) || 12;
      h = (h < 10) ? ('0' + h) : h;  // leading 0 at the left for 1 digit hours
      const ampm = H < 12 ? ' AM' : ' PM';
      time = h + time.substr(2, 3) + ampm;
      return time;
    }*/
  showDoctorWorkingTimes(i: number, availablity: Availablity[]) {
    this.doctorTimes.setTimes(availablity);
    this.doctorTimes.open();
  }

  searchDoctors() {
    let search = '';
    this.searchBy.validation = '';
    if (this.searchBy.type === '0') {
      this.loadDoctors();
      return;
    } else if (this.searchBy.type === '1') {
      // search by specialization.
      if (this.searchBy.speciality === '') {
        this.searchBy.validation = 'errors.select_search_criteria';
        return;
      }
      search += '?specialityId=' + this.searchBy.speciality + '&lang=all';
    } else if (this.searchBy.type === '2') {
      // search by hospital.
      if (this.searchBy.centerId === '') {
        this.searchBy.validation = 'errors.select_search_criteria';
        return;
      }
      search += '?centerId=' + this.searchBy.centerId + '&lang=all';
    } else if (this.searchBy.type === '3') {
      // search by nationality.
      if (this.searchBy.nationality === '') {
        this.searchBy.validation = 'errors.select_search_criteria';
        return;
      }
      search += '?nationalityId=' + this.searchBy.nationality + '&lang=all';
    } else {
      // search by center name.
      if (this.searchBy.centerName === '') {
        this.searchBy.validation = 'errors.select_search_criteria';
        return;
      }
      search += '?keyword=' + this.searchBy.centerName + '&lang=all';
    }

    this.doctors = [];
    this.doctorApi.search(search).subscribe(res => {
      this.doctors = res.data;
    });
  }

  private loadNationalities() {
    this.nationalityApi.nationalities(1, 10000).subscribe(res => {
      this.nationalities = res.data;
    });
  }

  private loadDoctorsCounters() {
    this.doctorApi.statistics().subscribe(res => {
      this.doctorsCounters = res.data[0];
    });
  }
}
