import {Directive, ElementRef, HostListener} from '@angular/core';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';

@Directive({
  selector: '[appArabicOnly]'
})
export class ArabicOnlyDirective {
  AllowedString = ' Backspace لإإ‘ألأٍِـْآ ذضصثقفغعهخحجدطكمنتالبيسشظزوةىلارؤءئ';
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-'];
  tr: TranslatePipe;

  constructor(private el: ElementRef) {

  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Allow Backspace, tab, end, and home keys
    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);
    if (this.AllowedString.indexOf(event.key) === -1) {
      event.preventDefault();
    }
  }
}
