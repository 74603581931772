import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {DoctorsResponse} from '../response/doctors/doctors-response';
import {Observable} from 'rxjs';
import {DoctorsCountersResponse} from '../response/doctors/doctors-counters-response';

@Injectable()
export class DoctorApi {

  constructor(private http: HttpClient) {

  }

  public save(data): Observable<DoctorsResponse> {
    return this.http.post<DoctorsResponse>(environment.apiLink.concat('doctors?lang=all'), data);
  }

  public doctors(page: number, size: number): Observable<DoctorsResponse> {
    return this.http.get<DoctorsResponse>(environment.apiLink.concat(`doctors?page=${page}&size=${size}&lang=all&status=ALL`));
  }


  public activate(id: string): Observable<DoctorsResponse> {
    return this.http.put<DoctorsResponse>(environment.apiLink.concat(`doctors/${id}/activate`), {});
  }

  public deactivate(id: string): Observable<DoctorsResponse> {
    return this.http.put<DoctorsResponse>(environment.apiLink.concat(`doctors/${id}/deactivate`), {});
  }

  public search(searchBy: string): Observable<DoctorsResponse> {
    return this.http.get<DoctorsResponse>(environment.apiLink.concat(`doctors/search` + searchBy));
  }


  public update(id: string, data): Observable<DoctorsResponse> {
    return this.http.put<DoctorsResponse>(environment.apiLink.concat(`doctors/${id}/?lang=all`), data);
  }

  public statistics(): Observable<DoctorsCountersResponse> {
    return this.http.get<DoctorsCountersResponse>(environment.apiLink.concat(`doctors/statistics`));
  }
}
