import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent} from '@angular/router';
import {Subscription} from 'rxjs';
import {CommonService} from '../service/common-service';
import {LanguageService} from '../service/language-service';

@Component({
  selector: 'app-index',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  private subscription: Subscription;

  constructor(private router: Router, private commonService: CommonService,
              private languageService: LanguageService) {
    languageService.setAppLanguage();
  }


  // Shows and hides the loading spinner during RouterEvent changes
  private _navigationInterceptor(event: RouterEvent): void {
    // find loader.
    if (event instanceof NavigationStart) {
      this.commonService.showLoader();
    }
    if (event instanceof NavigationEnd) {
      this.commonService.hideLoader();
    }

    if (event instanceof NavigationCancel) {
      this.commonService.hideLoader();
    }
    if (event instanceof NavigationError) {
      this.commonService.hideLoader();
    }
  }


  ngOnInit(): void {
    this.subscription = this.router.events.subscribe((event: RouterEvent) => {
      this._navigationInterceptor(event);
    });
  }


  ngOnDestroy(): void {
    if (this.subscription != null) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }
}
